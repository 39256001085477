// ----------------------------------------------------------------------

const ROOTS = {
  APP: "/:lang/app",
  AUTH: "/auth",
  AUTH_DEMO: "/auth-demo",
  DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: "https://mui.com/store/items/minimal-dashboard/",
  // MAIN APP
  app: {
    home: `/:lang`,
    create: `${ROOTS.APP}/create`,
    options: `${ROOTS.APP}/create/options`,
    searchResult: `${ROOTS.APP}/search-result`,
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
  },
  // DASHBOARD
  dashboard: {
    profile: `${ROOTS.DASHBOARD}/profile`,
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/two`,
    three: `${ROOTS.DASHBOARD}/three`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },
};

export function getPathWithLang(path: string, lang: string) {
  return path.replace(":lang", lang);
}
