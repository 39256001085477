import { lazy, Suspense } from "react";
import { Navigate, Outlet, RouteObject } from "react-router";
import { LoadingScreen } from "src/components/loading-screen";
import MainLayout from "src/layouts/main";
import { paths } from "../paths";
import { dashboardRoutes } from "./dashboard";

const HomePage = lazy(() => import("src/pages/app/home"));
const CreatePage = lazy(() => import("src/pages/app/create"));
const OptionsPage = lazy(() => import("src/pages/app/options"));
const SearchResultPage = lazy(() => import("src/pages/app/search-result"));

export const appRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <MainLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      // { element: <Navigate to={paths.app.home} replace /> },
      { element: <Navigate to="/en" replace /> },
      { path: paths.app.home, element: <HomePage /> },
      { path: paths.app.create, element: <CreatePage /> },
      { path: paths.app.options, element: <OptionsPage /> },
      { path: paths.app.searchResult, element: <SearchResultPage /> },
      ...dashboardRoutes,
    ],
  },
];
