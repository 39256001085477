import { Navigate, useRoutes } from "react-router-dom";
// config
//
import { mainRoutes } from "./main";
import { appRoutes } from "./app";
import { authRoutes } from "./auth";
// import { paths } from '../paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/en" replace />,
      // element: <Navigate to={paths.app.home} replace />,
    },
    // App routes
    ...appRoutes,

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    // ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
